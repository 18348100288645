import {createRouter, createWebHashHistory} from "vue-router";

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('./views/index.vue'),
            meta: {
                title: '首页',
                showHeaderFooter: true
            }
        },
        {
            path: '/levels',
            name: 'levels',
            component: () => import('./views/levels.vue'),
            meta: {
                title: '趣味编程',
                showHeaderFooter: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/login.vue'),
            meta: {
                title: '登录',
                showHeaderFooter: true
            }
        },
        {
            path: '/blocklyGame',
            name: 'blocklyGame',
            component: () => import('./views/blocklyGame.vue'),
            meta: {
                showHeaderFooter: false
            }
        },
        {
            path: '/addVip',
            name: 'addVip',
            component: () => import('./views/addVip.vue'),
            meta: {
                showHeaderFooter: true
            }
        },
        {
            path: '/admin',
            name: 'admin',
            component: () => import('./views/admin.vue'),
            meta: {
                showHeaderFooter: false
            },
            children: [
                {
                    path: 'editMap',
                    name: 'editMap',
                    component: () => import('./views/editMap.vue'),
                },
                {
                    path: 'userManager',
                    name: 'userManager',
                    component: () => import('./views/userManager.vue'),
                },
            ]
        }
    ]
})