<script setup>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {onMounted} from "vue";
import {myLocalStorage} from "@/store/myLocalStorage";
import axios from "axios";

onMounted(() => {
  if (myLocalStorage.getItem("userInfo")) {
    let userInfo = JSON.parse(myLocalStorage.getItem("userInfo"));
    let refreshUserInfo = axios.get(`getUserInfo?uid=${userInfo.uid}`);
    Promise.resolve(refreshUserInfo).then(res => {
      if (res.data.code === 1) myLocalStorage.setItem("userInfo", JSON.stringify(res.data.data[0]));
      else myLocalStorage.removeItem("userInfo");
    }).catch(err => console.log(err));
  }
});
</script>

<template>
  <Header v-show="$route.meta.showHeaderFooter"/>
  <RouterView/>
  <Footer v-show="$route.meta.showHeaderFooter"/>
</template>

<style>
* {
  margin: 0;
}
</style>
