<script setup>

</script>

<template>
  <el-card style="text-align: center;">
    <el-text>乐而来编程</el-text>
  </el-card>
</template>

<style scoped>

</style>